<template>
  <b-row>
    <b-col
      cols="12"
    >
      <b-form-group
        :label="title"
        label-class="font-weight-bolder"
      >
        <v-select
          v-model="selected"
          :dir="$store.state.appConfig.isRTL ? 'rtl' : 'ltr'"
          label="title"
          :options="selectOptions"
        />
        <!-- {{ selected }} -->
      </b-form-group>
    </b-col>
  </b-row>
</template>

<script>
import {
  BFormGroup, BRow, BCol,
} from 'bootstrap-vue'

import vSelect from 'vue-select'

export default {
  components: {
    vSelect,
    BFormGroup,
    BRow,
    BCol,
  },
  props: {
    title: {
      type: String,
      required: true,
    },
    selectOptions: {
      type: Array,
      required: false,
      default: (() => []),
    },
  },
  data() {
    return {
      dir: 'ltr',
      selected: [],
    }
  },
  computed: {
    selectedComputed() {
      return this.selected
    },
  },
  watch: {

  },
}
</script>
