/* eslint-disable */
define(["require", "exports"], function (require, exports) {
  "use strict";
  Object.defineProperty(exports, "__esModule", { value: true });
  exports.employeeData = [{
      'id': 1,
      'nome': 'Davolio',
      'descrizione': 'Nancy2121',
      'tipo': 'Checkbox',
      'valore': 'Vero'
  },
  {
    'id': 2,
    'nome': 'Davolio',
    'descrizione': 'Nancy23123',
    'tipo': 'Checkbox',
    'valore': 'Vero'
  },
  {
    'id': 3,
    'nome': 'Davolio',
    'descrizione': 'Nancy344',
    'tipo': 'Checkbox',
    'valore': 'Vero'
  },
  {
    'id': 4,
    'nome': 'Davolio2222',
    'descrizione': 'Nancy',
    'tipo': 'Checkbox',
    'valore': 'Vero'
  },
  {
    'id': 5,
    'nome': 'Davoli1111',
    'descrizione': 'Nancy',
    'tipo': 'Checkbox',
    'valore': 'Vero'
  },
  {
    'id': 6,
    'nome': 'Davol1111io',
    'descrizione': 'Nancy',
    'tipo': 'Checkbox',
    'valore': 'Vero'
  },
  {
    'id': 7,
    'nome': 'Davol606io',
    'descrizione': 'Nancy',
    'tipo': 'Checkbox',
    'valore': 'Vero'
  },
  {
    'id': 8,
    'nome': 'Davo777io',
    'descrizione': 'Nancy',
    'tipo': 'Checkbox',
    'valore': 'Vero'
  },
  {
    'id': 9,
      'nome': 'Dav222olio',
      'descrizione': 'Nancy',
      'tipo': 'Checkbox',
      'valore': 'Vero'
  }];   
});
