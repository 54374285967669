<template>
  <b-tabs
    ref="manage-setAttributes"
    vertical
    content-class="col-12 col-md-10 mt-1 mt-md-0"
    pills
    nav-wrapper-class="col-md-2 col-12"
    nav-class="nav-left"
  >
    <b-tab>

      <!-- title -->
      <template #title>
        <feather-icon
          icon="LockIcon"
          size="18"
          class="mr-50"
        />
        <span class="font-weight-bold">SetAttributi</span>
      </template>

      <ListSetAttributes />
    </b-tab>
    <!--/ change password tab -->

    <!-- info -->
    <b-tab>

      <!-- title -->
      <template #title>
        <feather-icon
          icon="InfoIcon"
          size="18"
          class="mr-50"
        />
        <span class="font-weight-bold">Attributi</span>
      </template>
      <ListAttributes />
    </b-tab>
  </b-tabs>
</template>

<script>
import {
  BTabs, BTab,
} from 'bootstrap-vue'
import ListSetAttributes from './ListSetAttributes.vue'
import ListAttributes from './ListAttributes.vue'

export default {
  components: {
    BTabs,
    BTab,
    ListSetAttributes,
    ListAttributes,
  },
  data() {
    return {
      solidColor: [
        { bg: 'primary', title: 'Registra Account', routeToGo: 'register-account-internal' },
        { bg: 'primary', title: 'Collaboratori', routeToGo: 'general-settings-users-collaborators2' },
        /* { bg: 'primary', title: 'Ruoli', routeToGo: 'general-settings-users-roles' }, */
        { bg: 'primary', title: 'Profili2', routeToGo: 'general-settings-users-profiles2' },
        /* { bg: 'primary', title: 'Gruppi', routeToGo: 'general-settings-users-groups' }, */
        { bg: 'primary', title: 'Login History', routeToGo: 'general-settings-users-login-history' },
        { bg: 'primary', title: 'Action History', routeToGo: 'general-settings-users-action-history' },
      ],
      historyServer: [],
      modificheHistoryServer: [],
      permissions: [],
      collaborators: [],
    }
  },
  computed: {

  },
  mounted() {
  },
  methods: {
  },
}
</script>
<style scoped>
  .cardPointer{
    position: relative;
    top: 0;
    transition: top ease 0.5s;
  }
  .cardPointer:hover{
    cursor: pointer;
    top: -10px;

  }
</style>
