<template>
  <b-modal
    id="addsetattribute"
    ref="addsetattribute"
    centered
    :visible="show"
    :title="titleData"
    hide-footer
    no-close-on-backdrop
    @close="show = false"
  >
    <validation-observer ref="validation-form-add-set-attributes">
      <b-row no-gutters>
        <b-col
          cols="12"
        >
          <b-form-group
            :label="'Nome Set Attributo'"
            label-class="font-weight-bolder"
          >
              <validation-provider
                #default="{ errors }"
                name="Nome Set Attributo"
                rules="required"
              >
                <b-form-input v-model="setAttribute.name" />
                <small class="text-danger">{{ errors[0] }}</small>
            </validation-provider>
          </b-form-group>
        </b-col>
      </b-row>
    </validation-observer>
    <b-button
      variant="primary"
      @click="addSetAttribute"
    >Salva</b-button>
  </b-modal>
</template>

<script>
import {
  BModal, BFormGroup, BFormInput, BCol, BRow, BButton,
} from 'bootstrap-vue'
import { ValidationProvider, ValidationObserver, localize } from 'vee-validate'
import { setSetAttributi } from '@/@core/api-service/set-attributi/setAttributiAttributi'

export default {
  components: {
    BModal,
    BFormGroup,
    BFormInput,
    BCol,
    BRow,
    BButton,
    ValidationProvider,
    ValidationObserver,
  },
  props: {
    attributeToEdit: {
      type: Object,
      required: false,
      default: (() => ({
        name: '',
        id: 0,
      })),
    },
    title: {
      type: String,
      required: false,
      default: 'Aggiungi Set Attributi',
    },
  },
  data() {
    return {
      show: false,
      setAttribute: this.attributeToEdit,
      titleData: this.title,
      locale: 'it',
    }
  },
  mounted() {
    localize(this.locale)
  },
  methods: {
    addSetAttribute() {
      // handle chiamata al server
      this.$refs['validation-form-add-set-attributes'].validate().then(success => {
        if (success) {
          setSetAttributi([{ ...this.setAttribute }]).then(res => {
            if (res.data.esito === 'OK') {
              const setSetAttributiAggiunti = [...res.data.setAttributiAggiunte]
              this.$emit('add-set-attribute', setSetAttributiAggiunti)
              this.setAttribute = {
                id: 0,
                name: '',
              }
              this.$refs.addsetattribute.hide()
            }
          }).catch(e => e)
        }
      }).catch(e => e)
    },
    editTitle(title) {
      this.titleData = title
    },
    editSet(value) {
      this.attribute = { ...value }
    },
    openSidebar() {
      this.show = true
    },
    closeSidebar() {
      this.show = false
    },
    // this.$refs.addaddress.hide()
    // e.preventDefault()
    // this.$refs.addaddress.hide()
    // this.$root.$on('bv::modal::hide', 'addaddress')
    // this.$refs.addaddress.hide()
  },
}
</script>

<style>

</style>
