<script>
/* eslint-disable vue/no-v-html */
</script>
<template>
  <b-card>
    <ejs-toast
      id="toast_type"
      ref="toastRef"
      :position="{ X: 'Right' }"
    />
    <b-row no-gutters>
      <b-col class="d-flex justify-content-end">
        <b-button
          v-b-modal="'addsetattribute'"
          variant="primary"
          @click="addSetAttribute('Aggiungi SetAttributo')"
        >
          Aggiungi Set Attributo
        </b-button>
        <add-set-attribute
          ref="addsetattribute"
           @add-set-attribute="addAttributeToList"
        />
        <associate-attributes-set-attribute-modal
          ref="associateAttributes"
          :setAttributoProp="setAttributoSelected"
          @association-completed="associationCompleted"
        />
      </b-col>
    </b-row>
    <div class="col-lg-12 control-section mt-2 p-0">
      <div class="content-wrapper">
        <ejs-grid
          id="overviewgrid"
          ref="overviewgrid"
          locale="it-IT"
          :data-source="data"
          :edit-settings="editSettings"
          :allow-filtering="true"
          :filter-settings="filterOptions"
          :toolbar-click="toolbarClick"
          :show-column-chooser="true"
          :allow-selection="false"
          :allow-sorting="true"
          :allow-resizing="true"
          :enable-virtualization="false"
          height="600"
          row-height="38"
          :enable-hover="false"
          :enable-header-focus="true"
          :toolbar="toolbar"
          :allow-grouping="false"
          :allow-paging="true"
          :page-settings="pageSettings"
          :data-bound="dataBound"
          :command-click="commandColumm"
        >
          <e-columns>
            <e-column
              field="id"
              header-text="id"
              width="80"
              :is-primary-key="true"
              :visible="false"
            />
            <e-column
              field="nome"
              header-text="Nome"
              width="150"
            />
            <e-column
              field="Azioni"
              :lock-column="true"
              header-text="Azioni"
              width="180"
              :allow-editing="false"
              :commands="commands"
              :allow-sorting="false"
              :allow-filtering="false"
            />
          </e-columns>
        </ejs-grid>
      </div>
    </div>

  </b-card>
</template>

<script>
/* eslint-disable no-plusplus */
/* eslint-disable no-multi-str */
/* eslint-disable vue/no-side-effects-in-computed-properties */

import Vue from 'vue'
import {
 GridPlugin, Sort, Filter, Selection, Toolbar, Edit, Page, ColumnChooser, CommandColumn, Resize, Scroll, Aggregate,
} from '@syncfusion/ej2-vue-grids'
import { ToastPlugin } from '@syncfusion/ej2-vue-notifications'
import {
  BButton, BCol, BRow, VBToggle, BCard, BCardText, BAvatar, BListGroupItem,
} from 'bootstrap-vue'
import Ripple from 'vue-ripple-directive'
import { italian } from '@/@core/grid-translation/it-IT'
import { L10n, setCulture } from '@syncfusion/ej2-base';
import draggable from 'vuedraggable'
import AddSetAttribute from './AddSetAttribute.vue'
// import { employeeData } from "./datasource.js";
import AssociateAttributesSetAttributeModal from './AssociateAttributesSetAttributeModal.vue'
import { getSetAttributi, deleteSetAttributi } from '@/@core/api-service/set-attributi/setAttributiAttributi'

Vue.use(GridPlugin)
Vue.use(ToastPlugin)

setCulture('it-IT');
L10n.load(italian)

export default Vue.extend({
  components: {
    BCard,
    BButton,
    BCol,
    BRow,
    AddSetAttribute,
    draggable,
    AssociateAttributesSetAttributeModal,
  },
  directives: {
    'b-toggle': VBToggle,
    Ripple,
  },
  data: () => ({
    data: [], // employeeData,
    setAttributoSelected: {},
    commands: [
        { type: 'Elimina', id: 'deleteCustom', buttonOption: { iconCss: 'e-icons e-delete', cssClass: 'e-flat' } },
        { type: 'Save', buttonOption: { iconCss: 'e-icons e-update', cssClass: 'e-flat' } },
        { type: 'Associa Attributi', id: 'associateAttributes', target: '.e-column', buttonOption: { iconCss: 'e-icons e-group-2', cssClass: 'e-flat' } },],
    contextMenuItems: ['AutoFit', 'AutoFitAll', 'SortAscending', 'SortDescending',
      'Copy', 'Save', 'FirstPage', 'PrevPage',
      'LastPage', 'NextPage',  'Group', 'Ungroup', { text: 'Associa Attributi', target: '.e-content', id: 'associateAttributes', iconCss: 'e-icons e-group-2' },
      ],
    pageSettings: { pageSize: 100, pageSizes: [25,50,100,200], pageCount: 5 },
    editSettings: { showDeleteConfirmDialog: false, allowEditing: false, allowAdding: true, allowDeleting: true, mode: 'Normal' },
    toolbar: ['ColumnChooser', 'Print'], // { text: 'Dettagli', tooltipText: 'Dettagli', prefixIcon: 'e-expand', id: 'Dettagli' }
    ddData: [{ value: 1000, text: '1,000 Rows and 11 Columns' }, { value: 10000, text: '10,000 Rows and 11 Columns' }],
    ddValue: 10000,
    stTime: null,
    loadTime: null,
    dReady: false,
    isDataChanged: true,
    fields: { text: 'text', value: 'value' },
    detailTemplate() {
      return { template : Vue.component('detailTemplate',{
        components: {
          draggable,
          BCol,
          BRow,
          BCardText,
          BAvatar,
          BListGroupItem,
        },
        data() {
          return {
            data: {},
            list1: [
              {
                id: 1,
                name: 'Dimensioni',
                attributi: [
                  {
                    id: '100',
                    valore: '25',
                    tag: 'SimpleInput',
                    type: 'text',
                    label: 'Larghezza',
                  },
                  {
                    id: '101',
                    valore: '10',
                    tag: 'SimpleInput',
                    type: 'text',
                    label: 'Lunghezza',
                  },
                  {
                    id: '102',
                    valore: '30',
                    tag: 'SimpleInput',
                    type: 'text',
                    label: 'Altezza',
                  },
                ],
              },
              {
                id: 2,
                name: 'Radio Input',
                attributi: [
                  {
                    id: '103',
                    tag: 'RadioInput',
                    valoreOption: [
                      { text: 'Item 1', value: 'first', disabled: false },
                      { text: 'Item 2', value: 'second', disabled: false },
                      { html: '<b>Item</b> 3', value: 'third', disabled: true },
                      { text: 'Item 4', value: 'Item 4', disabled: false },
                      { text: 'Item 5', value: 'E', disabled: false },
                    ],
                    label: 'Radio BAlori',
                  },
                ],
              },
              {
                id: 3,
                name: 'vue select',
                attributi: [
                  {
                    id: 104,
                    tag: 'SelectPushable',
                    selectOptions: ['select1', 'select2', 'select3'],
                    label: 'vue select',
                  },
                ],
              },
              {
                id: 4,
                name: 'Marlin Hinchee',
                email: 'preholding@scuffly.co.uk',
              },
            ],
            list2: [
              {
                name: 'Leia Atienza',
                email: 'unmeasurableness@interlamellar.co.uk',
              },
              {
                name: 'Lashawna Vaudrainm',
                email: 'soaking@khubber.com',
              },
              {
                name: 'Liliana Henscheid',
                email: 'lecideine@turndown.org',
              },
              {
                name: 'Keven Kolter',
                email: 'nontenure@anglicanum.co.uk',
              },
            ],
          }
        },
        template:
          `
          <div>
            <b-row>
        <!-- people group 1 -->
        <b-col md="4" class="m-auto">
          <h6 class="text-primary font-weight-bold mb-2">
            Attributi Assegnati
          </h6>

          <!-- draggable -->
          <draggable
            :list="list1"
            tag="ul"
            group="people"
            class="list-group list-group-flush cursor-move"
          >
            <b-list-group-item
              v-for="(listItem, index) in list1"
              :key="index"
              tag="li"
            >
              <div
                class="d-flex"
              >
                <b-avatar :text="listItem.name.slice(0,2)" />
                <div class="ml-25">
                  <b-card-text class="mb-0 font-weight-bold">
                    {{ listItem.name }}
                  </b-card-text>
                </div>
              </div>
            </b-list-group-item>
          </draggable>
        </b-col>

        <!-- people group 2 -->
        <b-col
          md="4"
          class="mt-sm-2 mt-md-0 m-auto"
        >
          <h6 class="text-primary font-weight-bold mb-2">
            Attributi Non Assegnati
          </h6>

          <!-- draggable -->
          <draggable
            :list="list2"
            tag="ul"
            group="people"
            class="list-group list-group-flush cursor-move"
          >
            <b-list-group-item
              v-for="(listItem, index) in list2"
              :key="index"
              tag="li"
            >
              <div
                class="d-flex"
              >
                <b-avatar :text="listItem.name.slice(0,2)" />
                <div class="ml-25">
                  <b-card-text class="mb-0 font-weight-bold">
                    {{ listItem.name }}
                  </b-card-text>
                </div>
              </div>
            </b-list-group-item>
          </draggable>
        </b-col>

        <!-- <b-col
          md="6"
          class="mt-1"
        >
          <prism
            language="javascript"
            class="rounded"
          >
            People Group 1: {{ list1 }}
          </prism>
        </b-col>

        <b-col
          md="6"
          class="mt-1"
        >
          <prism
            language="javascript"
            class="rounded"
          >
            People Group 2: {{ list2 }}
          </prism>
        </b-col> -->
      </b-row>
          </div>
          `,
        }),
      }
    },
    filterOptions: {
      type: 'Menu',
    },
    filter: {
      type: 'Menu',
    },
  }),
  mounted() {
    getSetAttributi().then(res => {
      if (res.data.esito === 'OK') {
        this.data = [...res.data.setAttributo].map(el => ({ id: el.id, nome: el.name }))
      }
    }).catch(e => e)
  },
 methods: {
    associationCompleted(bool) {
      console.log('bool', bool)
      this.$refs.toastRef.show({
        title: 'Operazione Completata!', content: 'Associazione eseguita Correttamente', cssClass: 'e-toast-success', icon: 'e-success e-circle-check',
      })
    },
    deleteSetAttributes(id) {
      deleteSetAttributi(id).then(res => {
        if (res.data.esito === 'OK') {
          res.data.setAttributiEliminate.forEach(el => {
            this.$refs.overviewgrid.deleteRecord('id', el.id)
          })
          this.$refs.toastRef.show({
            title: 'Operazione Completata!', content: 'Set Attributo Eliminato/i Correttamente', cssClass: 'e-toast-success', icon: 'e-success e-circle-check',
          })
        }
      }).catch(e => e)
    },
    alertAndConfirm(id) {
      // console.log('sono su alert confirm id', id)
      this.$swal({
        title: 'Sei Sicuro?',
        text: 'Verranno rimossi permanentemente',
        icon: 'warning',
        showCancelButton: true,
        confirmButtonText: 'Ok',
        cancelButtonText: 'Annulla',
        customClass: {
          confirmButton: 'btn btn-primary',
          cancelButton: 'btn btn-outline-danger ml-1',
        },
        buttonsStyling: false,
      }).then(res => {
        if (res.value) {
          this.deleteSetAttributes(id)
        }
      })
    },
    commandColumm(args) {
      if(args.commandColumn.id === 'associateAttributes') {
        this.setAttributoSelected = {
          nome: args.rowData.nome,
          id: args.rowData.id,
        }
        this.$refs['associateAttributes'].openModal()
      } else if (args.commandColumn.id === 'deleteCustom') {
        this.alertAndConfirm([{ id: args.rowData.id }])
      }
    },
    toolbarClick(args) {
      // console.log(args)
      if (args.item.id === 'Dettagli') {
        this.$refs.addsetattribute.openSidebar()
      }
      if (args.item.id === 'overviewgrid_pdfexport') { // 'Grid_pdfexport' -> Grid component id + _ + toolbar item name
          const exportProperties = {
              //you can customize the name as per your requirement
              fileName: 'prova.pdf',
            }
          // this.$bvModal.show('setting-pdf-export-modal')
          this.$refs.overviewgrid.pdfExport(exportProperties)
          // this.$refs.overviewgrid.pdfExport(exportProperties)
      }
      if (args.item.id === 'overviewgrid_excelexport') { // 'Grid_excelexport' -> Grid component id + _ + toolbar item name
          this.$refs.overviewgrid.excelExport()
      }
      if (args.item.id === 'overviewgrid_csvexport') { // 'Grid_excelexport' -> Grid component id + _ + toolbar item name
          this.$refs.overviewgrid.csvExport()
      }
     },
    // modal per aggiungere un set
    addSetAttribute(title) {
      this.$refs.addsetattribute.editTitle(title)
      this.$refs.addsetattribute.editSet({ name: '' })
    },
    addAttributeToList(data) {
      const dataMapped = data.map(el => ({ nome: el.name, id: el.id }))
      dataMapped.forEach(element => {
        // this.data.push(element)
        const lastIndex = this.data.length
        this.$refs.overviewgrid.addRecord(element, lastIndex)
      })
      this.$refs.toastRef.show({
        title: 'Operazione Completata!', content: 'Set Attributo Aggiunto Correttamente', cssClass: 'e-toast-success', icon: 'e-success e-circle-check',
      })
      // push su source data table dopo chiamata al server(chiamata sulla modal)
    },
    dataBound: function() {
      // console.log(this.$refs.overviewgrid)
      this.$refs.overviewgrid.autoFitColumns();
    },
  },
  provide: {
    grid: [Toolbar, Filter, Selection, Sort, Edit, Page, ColumnChooser, CommandColumn, Resize, Scroll, Aggregate],
  },
})

</script>
<style scoped>
/* @import "../../../../../../node_modules/@syncfusion/ej2-vue-grids/styles/bootstrap.css"; */
</style>
