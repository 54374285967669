<template>
  <b-row>
    <b-col
      cols="12"
    >
      <b-form-group
        :label="title"
        label-class="font-weight-bolder"
      >
        <b-form-checkbox-group
          :options="checkOptions"
          name="check-attribute-custom"
          class="demo-inline-spacing"
        />
      </b-form-group>
    </b-col>
  </b-row>
</template>

<script>
import {
  BFormGroup, BFormCheckboxGroup, BRow, BCol,
} from 'bootstrap-vue'

export default {
  components: {
    BFormGroup,
    BFormCheckboxGroup,
    BRow,
    BCol,
  },
  props: {
    title: {
      type: String,
      required: true,
    },
    checkOptions: {
      type: Array,
      required: false,
      default: (() => []),
    },
  },
  data() {
    return {
      valueInput: this.value,
    }
  },
}
</script>
<style>

</style>
