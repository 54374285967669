<template>
  <b-row>
    <b-col
      cols="12"
    >
      <b-form-group
        :label="title"
        label-class="font-weight-bolder"
      >
        <b-form-input
          v-model="valueInput"
          type="number"
        />
      </b-form-group>
    </b-col>
  </b-row>
</template>

<script>
import {
  BFormGroup, BFormInput, BRow, BCol,
} from 'bootstrap-vue'

export default {
  components: {
    BFormInput,
    BFormGroup,
    BRow,
    BCol,
  },
  props: {
    title: {
      type: String,
      required: true,
    },
    value: {
      type: String,
      required: true,
    },
  },
  data() {
    return {
      valueInput: this.value,
    }
  },
}
</script>
<style>

</style>
