/* eslint-disable  */
import axios from 'axios'

export function getAttributi() {
  // console.log('dentro logiiinnn')
  return new Promise((resolve, reject) => {
    axios.post(process.env.VUE_APP_URL_API_SERVER, {},
        {
            headers: {
                Authorization: `Bearer ${sessionStorage.getItem('token')}`,
                organizzazione: JSON.parse(sessionStorage.getItem('activeAgency')).value,
                classe: 'Diz-Attributo',
                metodo: 'getAttributi',
                ACL: '',
            },
        })
      .then(res => resolve(res))
      .catch(error => reject(error))
  })
}

export function setAttributi(setAttributo) {
  // console.log('dentro logiiinnn')
  return new Promise((resolve, reject) => {
    axios.post(process.env.VUE_APP_URL_API_SERVER, setAttributo,
        {
            headers: {
                Authorization: `Bearer ${sessionStorage.getItem('token')}`,
                organizzazione: JSON.parse(sessionStorage.getItem('activeAgency')).value,
                classe: 'Diz-Attributo',
                metodo: 'setAttributi',
                ACL: '',
            },
        })
      .then(res => resolve(res))
      .catch(error => reject(error))
  })
}

export function deleteAttributi(setAttributo) {
  // console.log('dentro logiiinnn')
  return new Promise((resolve, reject) => {
    axios.post(process.env.VUE_APP_URL_API_SERVER, setAttributo,
        {
            headers: {
                Authorization: `Bearer ${sessionStorage.getItem('token')}`,
                organizzazione: JSON.parse(sessionStorage.getItem('activeAgency')).value,
                classe: 'Diz-Attributo',
                metodo: 'deleteAttributi',
                ACL: '',
            },
        })
      .then(res => resolve(res))
      .catch(error => reject(error))
  })
}

export function getComponentiAttributo() {
  // console.log('dentro logiiinnn')
  return new Promise((resolve, reject) => {
    axios.post(process.env.VUE_APP_URL_API_SERVER, {},
        {
            headers: {
                Authorization: `Bearer ${sessionStorage.getItem('token')}`,
                organizzazione: JSON.parse(sessionStorage.getItem('activeAgency')).value,
                classe: 'Diz-ComponenteAttributo',
                metodo: 'getComponentiAttributi',
                ACL: '',
            },
        })
      .then(res => resolve(res))
      .catch(error => reject(error))
  })
}

export function getAttributiAssociatiNonAssociati(idSetAttributi) {
  // console.log('dentro logiiinnn')
  return new Promise((resolve, reject) => {
    axios.post(process.env.VUE_APP_URL_API_SERVER, idSetAttributi,
        {
            headers: {
                Authorization: `Bearer ${sessionStorage.getItem('token')}`,
                organizzazione: JSON.parse(sessionStorage.getItem('activeAgency')).value,
                classe: 'Diz-Attributo',
                metodo: 'getAttributiAssociatiNonAssociati',
                ACL: '',
            },
        })
      .then(res => resolve(res))
      .catch(error => reject(error))
  })
}
export function setSetAttributiAttributi(associazione) {
  // console.log('dentro logiiinnn')
  return new Promise((resolve, reject) => {
    axios.post(process.env.VUE_APP_URL_API_SERVER, associazione,
        {
            headers: {
                Authorization: `Bearer ${sessionStorage.getItem('token')}`,
                organizzazione: JSON.parse(sessionStorage.getItem('activeAgency')).value,
                classe: 'Diz-SetAttributoAttributo',
                metodo: 'setSetAttributiAttributi',
                ACL: '',
            },
        })
      .then(res => resolve(res))
      .catch(error => reject(error))
  })
}