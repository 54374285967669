<template>
  <b-modal
    id="associateAttributes"
    ref="associateAttributes"
    centered
    :visible="show"
    :title="'Gestisci Associazione Attributi'"
    ok-title="Salva"
    ok-only
    size="lg"
    no-close-on-backdrop
    @close="closeModal"
    @ok="associateAttributes"
    @show="getAttributeAssociateNotAssociate"
  >
    <h5>Set Attributo Selezionato: <strong>{{ setAttributoProp.nome }}</strong></h5>
    <b-row class="mt-2">
      <b-col lg="6">
        <h6>Attributi Associati</h6>
        <draggable
          :list="attributiAssociati"
          :group="{ name:'attributi' }"
          class="mb-1 cursor-move"
          style="border-style: dashed; height: 150px"
        >
          <div v-if="attributiAssociati.length === 0">
            Nessun Attributi Associati
          </div>
          <b-badge
            v-for="(listItem, index) in attributiAssociati"
            v-else
            :key="index"
            class="m-25"
            variant="primary"
          >
            {{ listItem.label }}
          </b-badge>
        </draggable>
      </b-col>

      <!-- add tag -->
      <b-col lg="6">
        <h6>Attributi Non Associati</h6>
        <draggable
          :list="attributiNonAssociati"
          :group="{ name:'attributi' }"
          class="mb-1 cursor-move"
          style="border-style: dashed; height: 150px"
        >
          <div v-if="attributiNonAssociati.length === 0">
            Nessun Attributo non associato
          </div>
          <b-badge
            v-for="(listItem, index) in attributiNonAssociati"
            v-else
            :key="index"
            class="m-25"
            variant="info"
          >
            {{ listItem.label }}
          </b-badge>
        </draggable>
      </b-col>
    </b-row>
  </b-modal>
</template>

<script>
import {
  BModal, BCol, BRow, BBadge,
} from 'bootstrap-vue'
import draggable from 'vuedraggable'
import { getAttributiAssociatiNonAssociati, setSetAttributiAttributi } from '@/@core/api-service/set-attributi/attributi'

export default {
  components: {
    BModal,
    BCol,
    BRow,
    draggable,
    BBadge,
  },
  props: {
    setAttributoProp: {
      type: Object,
      required: true,
    },
  },
  data() {
    return {
      show: false,
      attributiAssociati: [],
      attributiNonAssociati: [],
    }
  },
  methods: {
    associateAttributes(e) {
      // console.log('ok premuto')
      e.preventDefault()
      // console.log('dopo prevent default')
      const associations = [{
        idSetAttributi: this.setAttributoProp.id,
        attributi: this.attributiAssociati.map(el => ({ id: el.id })),
      }]
      setSetAttributiAttributi(associations).then(res => {
        if (res.data.esito === 'OK') {
          // console.log(res)
          // emit associazione completata
          this.$emit('association-completed', true)
          this.attribute = { name: '' }
          this.show = false
        }
      }).catch(err => err)
      this.$emit('add-set-attribute', this.attribute)
      // this.$refs.addsetattribute.hide()
    },
    editSet(value) {
      this.attribute = { ...value }
    },
    openModal() {
      this.show = true
    },
    closeModal() {
      this.show = false
      this.attributiAssociati = []
      this.attributiNonAssociati = []
    },
    getAttributeAssociateNotAssociate() {
      // console.log({ idSetAttributo: this.setAttributoProp.id })
      getAttributiAssociatiNonAssociati([{ idSetAttributo: this.setAttributoProp.id }]).then(res => {
        // console.log(res)
        if (res.data.esito === 'OK') {
          this.attributiAssociati = [...res.data.attributiAssociati]
          this.attributiNonAssociati = [...res.data.attributiNonAssociati]
        }
      }).catch(e => e)
    },
    // this.$refs.addaddress.hide()
    // e.preventDefault()
    // this.$refs.addaddress.hide()
    // this.$root.$on('bv::modal::hide', 'addaddress')
    // this.$refs.addaddress.hide()
  },
}
</script>

<style>

</style>
