<template>
  <div>
    <b-sidebar
      id="addattribute"
      ref="addattribute"
      sidebar-class="sidebar-lg"
      bg-variant="white"
      backdrop-variant="dark"
      backdrop
      :no-close-on-backdrop="true"
      shadow
      no-header
      right
      :visible="show"
      @change="(val) => $emit('update:is-event-handler-sidebar-active', val)"
      @close="show = false"
      @hidden="show = false"
    >

      <template #default="{ hide }">
        <!-- Header -->
        <div class="d-flex justify-content-between align-items-center content-sidebar-header px-2 py-1">
          <h5 class="mb-0">
            {{ titleData }}
          </h5>
          <div>
            <feather-icon
              v-if="title === 'Modifica Collaboratore'"
              icon="TrashIcon"
              class="cursor-pointer"
              @click="$emit('remove-event', form); hide();"
            />
            <feather-icon
              class="ml-1 cursor-pointer"
              icon="XIcon"
              size="16"
              @click="closeSidebar(); hide()"
            />
          </div>
        </div>
        <validation-observer
          ref="addattributerules"
          tag="form"
        >
          <b-row class="m-1">
            <b-col cols="12">
              <b-form-group
                label="Tipo di Attributo"
                label-for="assign-attribute"
                label-class="font-weight-bolder"
              >
                <validation-provider
                  #default="{ errors }"
                  name="Tipo Attributo"
                  rules="required"
                >
                  <v-select
                    v-model="attribute.component"
                    :dir="$store.state.appConfig.isRTL ? 'rtl' : 'ltr'"
                    :multiple="false"
                    :clearable="false"
                    label="component"
                    placeholder="Seleziona Tipo Attributo/i"
                    :options="typesAttribute"
                    :reduce="(val) => ({ id: val.id, component: val.component })"
                    @open="getTipiComponente"
                  >
                    <!-- eslint-disable-next-line vue/no-unused-vars  -->
                    <template #no-options="{ open, searching, loading }">
                      Caricamento Tipi
                    </template>
                  </v-select>
                  <small class="text-danger">{{ errors[0] }}</small>
                </validation-provider>
              </b-form-group>
            </b-col>
            <!-- {{ attribute }} -->
          </b-row>
          <b-row class="m-1">
            <b-col
              cols="12"
            >
              <validation-provider
                #default="{ errors }"
                name="Nome Attributo"
                rules="required"
              >
                <b-form-group
                  label="Nome Attributo"
                  label-for="assign-roles"
                  label-class="font-weight-bolder"
                >
                  <b-form-input v-model="attribute.nome" />
                  <small class="text-danger">{{ errors[0] }}</small>
                </b-form-group>
              </validation-provider>
            </b-col>
          </b-row>
          <b-row
            v-if="attribute.component.component === 'SelectInput'"
            class="m-1"
          >
            <b-col
              cols="12"
            >
              <validation-provider
                #default="{ errors }"
                name="Opzione Select"
                rules="required"
              >
                <b-form-group
                  label="Aggiungi Opzione Select"
                  label-for="assign-roles"
                  label-class="font-weight-bolder"
                >
                  <b-input-group>
                    <b-form-input
                      v-model="attributeToAddSelect"
                    />
                    <b-input-group-append>
                      <b-button
                        variant="primary"
                        @click="pushOptionToSelect"
                      >
                        Aggiungi
                      </b-button>
                    </b-input-group-append>
                  </b-input-group>
                  <small class="text-danger mt-25">{{ errors[0] }}</small>
                </b-form-group>
              </validation-provider>
            </b-col>
          </b-row>
          <b-row
            v-if="attribute.component.component === 'SelectPushable'"
            class="m-1"
          >
            <b-col
              cols="12"
            >
              <validation-provider
                #default="{ errors }"
                name="Opzione Select"
                :rules="attribute.valore.length === 0 ? 'required' : ''"
              >
                <b-form-group
                  label="Aggiungi Opzione Select"
                  label-for="assign-roles"
                  label-class="font-weight-bolder"
                >
                  <b-input-group>
                    <b-form-input
                      v-model="attributeToAddSelect"
                    />
                    <b-input-group-append>
                      <b-button
                        variant="primary"
                        @click="pushOptionToSelect"
                      >
                        Aggiungi
                      </b-button>
                    </b-input-group-append>
                  </b-input-group>
                  <small class="text-danger mt-25">{{ errors[0] }}</small>
                </b-form-group>
              </validation-provider>
            </b-col>
          </b-row>
          <b-row
            v-if="attribute.component.component === 'RadioInput'"
            class="m-1"
          >
            <b-col
              cols="12"
            >
              <validation-provider
                #default="{ errors }"
                name="Radio Options"
                :rules="attribute.valore.length === 0 ? 'required' : ''"
              >
                <b-form-group
                  label="Aggiungi Opzione Radio"
                  label-for="assign-roles"
                  label-class="font-weight-bolder"
                >
                  <b-input-group>
                    <b-form-input
                      v-model="attributeToAddRadio"
                    />
                    <b-input-group-append>
                      <b-button
                        variant="primary"
                        @click="pushOptionRadio"
                      >
                        Aggiungi
                      </b-button>
                    </b-input-group-append>
                  </b-input-group>
                  <small class="text-danger mt-25">{{ errors[0] }}</small>
                </b-form-group>
              </validation-provider>
            </b-col>
          </b-row>
          <b-row
            v-if="attribute.component.component === 'CheckboxInput'"
            class="m-1"
          >
            <b-col
              cols="12"
            >
              <validation-provider
                #default="{ errors }"
                name="Checkbox Options"
                :rules="attribute.valore.length === 0 ? 'required' : ''"
              >
                <b-form-group
                  label="Aggiungi Opzione Checkbox"
                  label-for="assign-roles"
                  label-class="font-weight-bolder"
                >
                  <b-input-group>
                    <b-form-input
                      v-model="attributeToAddCheckBox"
                    />
                    <b-input-group-append>
                      <b-button
                        variant="primary"
                        @click="pushOptionCheckBox"
                      >
                        Aggiungi
                      </b-button>
                    </b-input-group-append>
                  </b-input-group>
                  <small class="text-danger mt-25">{{ errors[0] }}</small>
                </b-form-group>
              </validation-provider>
            </b-col>
          </b-row>
        </validation-observer>
        <b-row class="m-1">
          <b-col cols="12">
            <b-list-group flush>
              <b-list-group-item
                v-for="(attributo, index) in attribute.valore"
                :key="index"
                class="d-flex justify-content-between align-items-center"
              >
                <span>{{ attributo }}</span>
                <div>
                  <b-button
                    v-ripple.400="'rgba(113, 102, 240, 0.15)'"
                    variant="outline-primary"
                    class="btn-icon mr-1"
                    @click="deleteAttribute(attributo)"
                  >
                    <img
                      :src="require('@/@core/assets/iconpro/'+ 'trash-xmark' +'.svg')"
                      width="18"
                      height="18"
                      class="mr-1 mx-auto"
                    >
                  </b-button>
                  <!-- <b-button
                    v-ripple.400="'rgba(113, 102, 240, 0.15)'"
                    variant="outline-primary"
                    class="btn-icon"
                  >
                    <img
                      :src="require('@/@core/assets/iconpro/'+ 'arrow-left' +'.svg')"
                      width="18"
                      height="18"
                      class="mr-1 mx-auto"
                    >
                  </b-button> -->
                </div>
              </b-list-group-item>
            </b-list-group>
          </b-col>
          <b-col class="mt-2">
            <!-- <h2>{{ attribute }}</h2> -->
            <div v-if="attribute.nome.length > 0">
              <h4>Preview:</h4>
              <component
                :is="attribute.component.component"
                :title="attribute.nome"
                :value="''"
                :select-options="attribute.valore"
                :radio-options="attribute.valore"
                :check-options="attribute.valore"
              />
            </div>
          </b-col>
        </b-row>
        <b-row class="m-1">
          <b-col class="d-flex justify-content-end">
            <b-button
              @click="closeSidebar"
            >
              Reset
            </b-button>
            <b-button
              class="ml-1"
              @click="saveAttribute"
            >
              Salva
            </b-button>
          </b-col>
        </b-row>
      </template>
    </b-sidebar>
  </div>
</template>

<script>
import {
  BSidebar, BRow, BCol, BFormGroup, BFormInput, BButton, BInputGroupAppend, BInputGroup, BListGroup, BListGroupItem,
} from 'bootstrap-vue'
import { ValidationProvider, ValidationObserver, localize } from 'vee-validate'
import Ripple from 'vue-ripple-directive'
import vSelect from 'vue-select'
import { required, email, url } from '@validations'
import SimpleInput from './customComponents/SimpleInput.vue'
import RadioInput from './customComponents/RadioInput.vue'
import SelectPushable from './customComponents/SelectPushable.vue'
import TextAreaInput from './customComponents/TextAreaInput.vue'
import SwitchYesNo from './customComponents/SwitchYesNo.vue'
import CheckboxInput from './customComponents/CheckboxInput.vue'
import SelectInput from './customComponents/SelectInput.vue'
import NumberInput from './customComponents/NumberInput.vue'
import { setAttributi, getComponentiAttributo } from '@/@core/api-service/set-attributi/attributi'

// import flatPickr from 'vue-flatpickr-component'
/* import {
  FormWizard, TabContent, WizardButton,
} from 'vue-form-wizard' */
// import formValidation from '@core/comp-functions/forms/form-validation'
// import { ref, toRefs } from '@vue/composition-api'
// import useColllaboratorsEventHandler from './useColllaboratorsEventHandler'

export default {
  components: {
    BFormGroup,
    BSidebar,
    BRow,
    BCol,
    ValidationProvider,
    ValidationObserver,
    vSelect,
    BFormInput,
    // BFormSelect,
    SimpleInput,
    RadioInput,
    SelectPushable,
    TextAreaInput,
    SwitchYesNo,
    CheckboxInput,
    SelectInput,
    NumberInput,
    BButton,
    BInputGroupAppend,
    BInputGroup,
    BListGroup,
    BListGroupItem,

    // BDropdown,
    // BDropdownItem,

    // FormWizard,
    // TabContent,
    // WizardButton,
  },
  directives: {
    Ripple,
  },
  /* model: {
    prop: 'isEventHandlerSidebarActive',
    event: 'update:is-event-handler-sidebar-active',
  }, */
  props: {
    attributeToEdit: {
      type: Object,
      required: false,
    },
    title: {
      type: String,
      required: false,
      default: 'Aggiungi Attributo',
    },
  },
  data() {
    return {
      required,
      email,
      url,
      show: false,
      titleData: this.title,
      locale: 'it',
      selectedEdit: false,
      token: `Bearer ${sessionStorage.getItem('token')}`,
      // typesAttribute: ['Campo di Testo', 'Area di Testo', 'Si/No', 'MultiSelect', 'Radio Button'],
      typesAttribute: [/*
        { label: 'Campo di Testo', component: 'SimpleInput' },
        { label: 'Numero', component: 'NumberInput' },
        { label: 'Area di Testo', component: 'TextAreaInput' },
        { label: 'Si/No', component: 'SwitchYesNo' },
        { label: 'MultiSelect', component: 'SelectPushable' },
        { label: 'Select', component: 'SelectInput' },
        { label: 'Radio Button', component: 'RadioInput' },
        { label: 'CheckBox', component: 'CheckboxInput' }, */
      ],
      attribute: {
        component: { id: '', component: '' },
        nome: '',
        valore: [],
        id: 0,
      },
      attributeToAddSelect: '',
      attributeToAddRadio: '',
      attributeToAddCheckBox: '',
    }
  },
  mounted() {
    localize(this.locale)
  },
  methods: {
    openSidebar() {
      this.show = true
    },
    setAttributeRef(attribute) {
      /* console.log('thisattribute', this.attribute)
      console.log('attribute', attribute) */
      this.attribute = { ...attribute }
    },
    editTitle(title) {
      this.titleData = title
    },
    pushOptionToSelect() {
      if (!this.attribute.valore.some(val => val === this.attributeToAddSelect)) {
        this.attribute.valore.push(this.attributeToAddSelect)
        this.attributeToAddSelect = ''
        this.attributeToAddRadio = ''
        this.attributeToAddCheckBox = ''
        this.$refs.addattributerules.reset()
      }
    },
    deleteAttribute(attributo) {
      // console.log(attributo)
      const indexFind = this.attribute.valore.findIndex(el => el === attributo)
      this.attribute.valore.splice(indexFind, 1)
    },
    pushOptionRadio() {
      if (!this.attribute.valore.some(val => val === this.attributeToAddRadio)) {
        this.attribute.valore.push(this.attributeToAddRadio)
        this.attributeToAddSelect = ''
        this.attributeToAddRadio = ''
        this.attributeToAddCheckBox = ''
        this.$refs.addattributerules.reset()
      }
    },
    pushOptionCheckBox() {
      if (!this.attribute.valore.some(val => val === this.attributeToAddCheckBox)) {
        this.attribute.valore.push(this.attributeToAddCheckBox)
        this.attributeToAddSelect = ''
        this.attributeToAddRadio = ''
        this.attributeToAddCheckBox = ''
        this.$refs.addattributerules.reset()
      }
    },
    closeSidebar() {
      this.attribute = {
        component: '',
        nome: '',
        valore: [],
        id: 0,
      }
      this.attributeToAddSelect = ''
      this.attributeToAddRadio = ''
      this.attributeToAddCheckBox = ''
      this.$refs.addattributerules.reset()
    },
    getTipiComponente() {
      getComponentiAttributo().then(res => {
        if (res.data.esito === 'OK') {
          this.typesAttribute = [...res.data.componenteAttributo].map(el => ({ id: el.id, component: el.componente, label: el.label }))
        }
      })
    },
    saveAttribute() {
      this.$refs.addattributerules.validate().then(success => {
        if (success) {
          const attributo = {
            id: this.attribute.id,
            label: this.attribute.nome,
            valore: this.attribute.valore.length > 0 ? this.attribute.valore : [''],
            idComponente: this.attribute.component.id,
          }
          // console.log('attributo', attributo)
          setAttributi([{ ...attributo }]).then(res => {
            // console.log(res)
            if (res.data.esito === 'OK') {
              let attributesAdded = []
              if (attributo.id === 0) {
                attributesAdded = [...res.data.attributiAggiunte]
              } else {
                attributesAdded = [...res.data.attributiAggiornate]
              }
              // console.log(attributesAdded)
              this.$emit('add-attribute', attributesAdded)
              // chiudo la sidebar
              this.show = false
            }
          }).catch(e => e)
        }
      })
    },
  },
}
</script>

<style lang="scss">
@import '@core/scss/vue/libs/vue-select.scss';
@import '@core/scss/vue/libs/vue-flatpicker.scss';
</style>
