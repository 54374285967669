var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('div',[_c('b-sidebar',{ref:"addattribute",attrs:{"id":"addattribute","sidebar-class":"sidebar-lg","bg-variant":"white","backdrop-variant":"dark","backdrop":"","no-close-on-backdrop":true,"shadow":"","no-header":"","right":"","visible":_vm.show},on:{"change":function (val) { return _vm.$emit('update:is-event-handler-sidebar-active', val); },"close":function($event){_vm.show = false},"hidden":function($event){_vm.show = false}},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var hide = ref.hide;
return [_c('div',{staticClass:"d-flex justify-content-between align-items-center content-sidebar-header px-2 py-1"},[_c('h5',{staticClass:"mb-0"},[_vm._v(" "+_vm._s(_vm.titleData)+" ")]),_c('div',[(_vm.title === 'Modifica Collaboratore')?_c('feather-icon',{staticClass:"cursor-pointer",attrs:{"icon":"TrashIcon"},on:{"click":function($event){_vm.$emit('remove-event', _vm.form); hide();}}}):_vm._e(),_c('feather-icon',{staticClass:"ml-1 cursor-pointer",attrs:{"icon":"XIcon","size":"16"},on:{"click":function($event){_vm.closeSidebar(); hide()}}})],1)]),_c('validation-observer',{ref:"addattributerules",attrs:{"tag":"form"}},[_c('b-row',{staticClass:"m-1"},[_c('b-col',{attrs:{"cols":"12"}},[_c('b-form-group',{attrs:{"label":"Tipo di Attributo","label-for":"assign-attribute","label-class":"font-weight-bolder"}},[_c('validation-provider',{attrs:{"name":"Tipo Attributo","rules":"required"},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var errors = ref.errors;
return [_c('v-select',{attrs:{"dir":_vm.$store.state.appConfig.isRTL ? 'rtl' : 'ltr',"multiple":false,"clearable":false,"label":"component","placeholder":"Seleziona Tipo Attributo/i","options":_vm.typesAttribute,"reduce":function (val) { return ({ id: val.id, component: val.component }); }},on:{"open":_vm.getTipiComponente},scopedSlots:_vm._u([{key:"no-options",fn:function(ref){
var open = ref.open;
var searching = ref.searching;
var loading = ref.loading;
return [_vm._v(" Caricamento Tipi ")]}}],null,true),model:{value:(_vm.attribute.component),callback:function ($$v) {_vm.$set(_vm.attribute, "component", $$v)},expression:"attribute.component"}}),_c('small',{staticClass:"text-danger"},[_vm._v(_vm._s(errors[0]))])]}}],null,true)})],1)],1)],1),_c('b-row',{staticClass:"m-1"},[_c('b-col',{attrs:{"cols":"12"}},[_c('validation-provider',{attrs:{"name":"Nome Attributo","rules":"required"},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var errors = ref.errors;
return [_c('b-form-group',{attrs:{"label":"Nome Attributo","label-for":"assign-roles","label-class":"font-weight-bolder"}},[_c('b-form-input',{model:{value:(_vm.attribute.nome),callback:function ($$v) {_vm.$set(_vm.attribute, "nome", $$v)},expression:"attribute.nome"}}),_c('small',{staticClass:"text-danger"},[_vm._v(_vm._s(errors[0]))])],1)]}}],null,true)})],1)],1),(_vm.attribute.component.component === 'SelectInput')?_c('b-row',{staticClass:"m-1"},[_c('b-col',{attrs:{"cols":"12"}},[_c('validation-provider',{attrs:{"name":"Opzione Select","rules":"required"},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var errors = ref.errors;
return [_c('b-form-group',{attrs:{"label":"Aggiungi Opzione Select","label-for":"assign-roles","label-class":"font-weight-bolder"}},[_c('b-input-group',[_c('b-form-input',{model:{value:(_vm.attributeToAddSelect),callback:function ($$v) {_vm.attributeToAddSelect=$$v},expression:"attributeToAddSelect"}}),_c('b-input-group-append',[_c('b-button',{attrs:{"variant":"primary"},on:{"click":_vm.pushOptionToSelect}},[_vm._v(" Aggiungi ")])],1)],1),_c('small',{staticClass:"text-danger mt-25"},[_vm._v(_vm._s(errors[0]))])],1)]}}],null,true)})],1)],1):_vm._e(),(_vm.attribute.component.component === 'SelectPushable')?_c('b-row',{staticClass:"m-1"},[_c('b-col',{attrs:{"cols":"12"}},[_c('validation-provider',{attrs:{"name":"Opzione Select","rules":_vm.attribute.valore.length === 0 ? 'required' : ''},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var errors = ref.errors;
return [_c('b-form-group',{attrs:{"label":"Aggiungi Opzione Select","label-for":"assign-roles","label-class":"font-weight-bolder"}},[_c('b-input-group',[_c('b-form-input',{model:{value:(_vm.attributeToAddSelect),callback:function ($$v) {_vm.attributeToAddSelect=$$v},expression:"attributeToAddSelect"}}),_c('b-input-group-append',[_c('b-button',{attrs:{"variant":"primary"},on:{"click":_vm.pushOptionToSelect}},[_vm._v(" Aggiungi ")])],1)],1),_c('small',{staticClass:"text-danger mt-25"},[_vm._v(_vm._s(errors[0]))])],1)]}}],null,true)})],1)],1):_vm._e(),(_vm.attribute.component.component === 'RadioInput')?_c('b-row',{staticClass:"m-1"},[_c('b-col',{attrs:{"cols":"12"}},[_c('validation-provider',{attrs:{"name":"Radio Options","rules":_vm.attribute.valore.length === 0 ? 'required' : ''},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var errors = ref.errors;
return [_c('b-form-group',{attrs:{"label":"Aggiungi Opzione Radio","label-for":"assign-roles","label-class":"font-weight-bolder"}},[_c('b-input-group',[_c('b-form-input',{model:{value:(_vm.attributeToAddRadio),callback:function ($$v) {_vm.attributeToAddRadio=$$v},expression:"attributeToAddRadio"}}),_c('b-input-group-append',[_c('b-button',{attrs:{"variant":"primary"},on:{"click":_vm.pushOptionRadio}},[_vm._v(" Aggiungi ")])],1)],1),_c('small',{staticClass:"text-danger mt-25"},[_vm._v(_vm._s(errors[0]))])],1)]}}],null,true)})],1)],1):_vm._e(),(_vm.attribute.component.component === 'CheckboxInput')?_c('b-row',{staticClass:"m-1"},[_c('b-col',{attrs:{"cols":"12"}},[_c('validation-provider',{attrs:{"name":"Checkbox Options","rules":_vm.attribute.valore.length === 0 ? 'required' : ''},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var errors = ref.errors;
return [_c('b-form-group',{attrs:{"label":"Aggiungi Opzione Checkbox","label-for":"assign-roles","label-class":"font-weight-bolder"}},[_c('b-input-group',[_c('b-form-input',{model:{value:(_vm.attributeToAddCheckBox),callback:function ($$v) {_vm.attributeToAddCheckBox=$$v},expression:"attributeToAddCheckBox"}}),_c('b-input-group-append',[_c('b-button',{attrs:{"variant":"primary"},on:{"click":_vm.pushOptionCheckBox}},[_vm._v(" Aggiungi ")])],1)],1),_c('small',{staticClass:"text-danger mt-25"},[_vm._v(_vm._s(errors[0]))])],1)]}}],null,true)})],1)],1):_vm._e()],1),_c('b-row',{staticClass:"m-1"},[_c('b-col',{attrs:{"cols":"12"}},[_c('b-list-group',{attrs:{"flush":""}},_vm._l((_vm.attribute.valore),function(attributo,index){return _c('b-list-group-item',{key:index,staticClass:"d-flex justify-content-between align-items-center"},[_c('span',[_vm._v(_vm._s(attributo))]),_c('div',[_c('b-button',{directives:[{name:"ripple",rawName:"v-ripple.400",value:('rgba(113, 102, 240, 0.15)'),expression:"'rgba(113, 102, 240, 0.15)'",modifiers:{"400":true}}],staticClass:"btn-icon mr-1",attrs:{"variant":"outline-primary"},on:{"click":function($event){return _vm.deleteAttribute(attributo)}}},[_c('img',{staticClass:"mr-1 mx-auto",attrs:{"src":require('@/@core/assets/iconpro/'+ 'trash-xmark' +'.svg'),"width":"18","height":"18"}})])],1)])}),1)],1),_c('b-col',{staticClass:"mt-2"},[(_vm.attribute.nome.length > 0)?_c('div',[_c('h4',[_vm._v("Preview:")]),_c(_vm.attribute.component.component,{tag:"component",attrs:{"title":_vm.attribute.nome,"value":'',"select-options":_vm.attribute.valore,"radio-options":_vm.attribute.valore,"check-options":_vm.attribute.valore}})],1):_vm._e()])],1),_c('b-row',{staticClass:"m-1"},[_c('b-col',{staticClass:"d-flex justify-content-end"},[_c('b-button',{on:{"click":_vm.closeSidebar}},[_vm._v(" Reset ")]),_c('b-button',{staticClass:"ml-1",on:{"click":_vm.saveAttribute}},[_vm._v(" Salva ")])],1)],1)]}}])})],1)}
var staticRenderFns = []

export { render, staticRenderFns }