<script>
/* eslint-disable */
</script>
<template>
    <b-card>
      <ejs-toast
        id="toast_type"
        ref="toastRef"
        :position="{ X: 'Right' }"
      />
	    <b-row>
        <b-col class="d-flex justify-content-end">
          <b-button
            v-b-toggle="'addattribute'"
            variant="primary"
            @click="addAttribute('Aggiungi Attributo')"
            class="mb-2"
          >
            Aggiungi Attributo
          </b-button>
          <AddAttribute
            ref="addattribute"
            @add-attribute="pushAttributeToTable"
          />
        </b-col>
    </b-row>
      <ejs-grid
        id="overviewgrid_attributes"
        ref="overviewgrid_attributes"
        :data-source="computedData"
        :selection-settings="selectionSettings"
        :edit-settings="editSettings"
        :filter-settings="filterOptions"
        :toolbar-click="toolbarClick"
        :show-column-chooser="true"
        :allow-selection="true"
        :allow-sorting="true"
        :allow-resizing="true"
        :enable-virtualization="false"
        height="600"
        row-height="38"
        :enable-hover="false"
        :enable-header-focus="true"
        :toolbar="toolbar"
        :allow-grouping="true"
        :allow-paging="true"
        :allow-filtering="true"
        :page-settings="pageSettings"
        :context-menu-items="contextMenuItems"
        :context-menu-click="contextMenuClick"
        :data-bound="dataBound"
        :command-click="commandColumm"   
      >
          <e-columns>
            <e-column 
              field='id' 
              headerText='id' 
              width='80' 
              :is-primary-key="true"
              :visible="false"
            ></e-column>
            <e-column field='label' headerText='Nome' width='100' ></e-column>
            <e-column field='labelComponente' headerText='Tipo' width='100' :allow-editing="false"></e-column>
            <e-column field='valore' headerText='Valore' width='100' :allow-filtering="false" :template='templateValore'></e-column>
            <e-column
              field="Azioni"
              :lock-column="true"
              header-text="Azioni"
              width="300"
              :allow-editing="false"
              :commands="commands"
              :allow-sorting="false"
              :allow-filtering="false"
            />
          </e-columns>
      </ejs-grid>
    </b-card>
</template>
<script>
import Vue from "vue";
import { GridPlugin, Sort, Filter, Selection, Toolbar, Edit, Page, ColumnChooser, RowDD, ContextMenu, CommandColumn, Resize, Scroll, Aggregate,
 } from "@syncfusion/ej2-vue-grids";
import { L10n, setCulture } from '@syncfusion/ej2-base';
import { italian } from '@/@core/grid-translation/it-IT'
import Ripple from 'vue-ripple-directive'
import { employeeData } from "./datasource.js";
import { Internationalization } from '@syncfusion/ej2-base';
import { BCard, BCol, BRow, BButton, VBToggle, BBadge } from 'bootstrap-vue'
import draggable from 'vuedraggable'
import AddAttribute from './AddAttribute.vue'
import { getAttributi, deleteAttributi } from '@/@core/api-service/set-attributi/attributi'
import { ToastPlugin } from '@syncfusion/ej2-vue-notifications'

let instance = new Internationalization();

Vue.use(GridPlugin);
Vue.use(ToastPlugin);
setCulture('it-IT');
L10n.load(italian)

export default {
  components: {
    BCard,
    draggable,
    BRow,
    BCol,
    AddAttribute,
    BButton,
  },
    directives: {
    'b-toggle': VBToggle,
    Ripple,
  },
  data: () => {
    return {
      attributes: [],
      attributeToEdit: {},
      filterOptions: {
        type: 'Menu',
      },
      toolbar: ['Print', 'ColumnChooser'], // { text: 'Dettagli', tooltipText: 'Dettagli', prefixIcon: 'e-expand', id: 'Dettagli' }
      pageSettings: { pageSize: 25, pageSizes: [25,50,100,200], pageCount: 5 },
      commands: [{ type: 'Edit', buttonOption: { iconCss: ' e-icons e-edit', cssClass: 'e-flat' } },
        { type: 'Save', buttonOption: { iconCss: 'e-icons e-update', cssClass: 'e-flat' } },
        { type: 'Cancel', buttonOption: { iconCss: 'e-icons e-cancel-icon', cssClass: 'e-flat' } },
        { type: 'Elimina', id: 'Delete', buttonOption: { iconCss: 'e-icons e-delete', cssClass: 'e-flat' } },
      ],
       contextMenuItems: ['AutoFit', 'AutoFitAll', 'SortAscending', 'SortDescending',
      'Copy', 'Edit', 'Save', 'Cancel','FirstPage', 'PrevPage',
      'LastPage', 'NextPage',  'Group', 'Ungroup', {text: 'Dettagli', target: '.e-content', id: 'Dettagli', iconCss: 'e-icons e-folder-open' },
        {
          text: 'Elimina', tooltipText: 'Delete', iconCss: 'e-icons e-delete', id: 'DeleteCustom',
        },
      ],
      editSettings: { showDeleteConfirmDialog: false, allowEditing: false, allowAdding: true, allowDeleting: true, mode: 'Normal' },
      selectionSettings: { persistSelection: false, type: 'Multiple', checkboxOnly: true },
      data: [],
      templateValore() {
        return {
          template: Vue.component('templateValore', {
            components: {
              BBadge,
            },
            data() { return { data: {} } },
            computed: {
              valori() {
                if (typeof this.data.valore === 'string') return JSON.parse(this.data.valore)
                return this.data.valore
              },
            },
            template: `
              <div class="d-flex">
                <div
                  v-for="(valore, index) in valori"
                  :key="index"
                >
                  <b-badge
                    v-if="valore !== ''"
                    variant="primary"
                    class="mr-1"
                  >
                    {{ valore }}
                  </b-badge>
                </div>
              </div>
            `,
          }),
        }
      },
    }
  },
  computed: {
    computedData() {
      return this.attributes
    },
  },
  mounted() {
    getAttributi().then(res => {
       if (res.data.esito === 'OK') {
        this.attributes = [...res.data.attributi]
       }
    }).catch(e => e)
  },
  methods: {
    commandColumm(args) {
      // console.log('commandColumm', args)
      if (args.commandColumn.type === 'Edit') {
          this.$refs.addattribute.editTitle('Modifica Attributo')
          // this.$refs['addsetattribute'].setFormToEdit(userEdited)
          const attributoParsed = {
              component: { id: args.rowData.idComponente, component: args.rowData.nomeComponente },
              nome: args.rowData.label,
              valore: typeof args.rowData.valore === 'string' ? JSON.parse(args.rowData.valore) : args.rowData.valore,
              id: args.rowData.id,
          }
          // this.attributeToEdit = { ...attributoParsed }
          this.$refs.addattribute.setAttributeRef(attributoParsed)
          this.$refs.addattribute.openSidebar()
        }
      if (args.commandColumn.type === 'Elimina') {
        // console.log([{ id: args.rowData.id }])
        this.alertAndConfirm([{ id: args.rowData.id }])
      }
    },
    alertAndConfirm(id) {
      // console.log('sono su alert confirm id', id)
      this.$swal({
        title: 'Sei Sicuro?',
        text: 'Verranno rimossi permanentemente',
        icon: 'warning',
        showCancelButton: true,
        confirmButtonText: 'Ok',
        cancelButtonText: 'Annulla',
        customClass: {
          confirmButton: 'btn btn-primary',
          cancelButton: 'btn btn-outline-danger ml-1',
        },
        buttonsStyling: false,
      }).then(res => {
        if (res.value) {
          this.deleteAttributes(id)
        }
      })
    },
    deleteAttributes(id) {
      // console.log('deleteAttributes', id)
      deleteAttributi(id).then(res => {
        if (res.data.esito === 'OK') {
          res.data.attributiEliminate.forEach(el => {
            this.$refs.overviewgrid_attributes.deleteRecord('id', el.id)
          })
          this.$refs.toastRef.show({
            title: 'Operazione Completata!', content: 'Attributo Eliminato/i Correttamente', cssClass: 'e-toast-success', icon: 'e-success e-circle-check',
          })
        }
      })
    },
    contextMenuClick(args) {
    // console.log('contextMenuClick', args)
      if(args.item.id === 'Dettagli') {
        this.$refs.addattribute.editTitle('Modifica Attributo')
        // this.$refs['sidebar-collaborators'].setFormToEdit(userEdited)
        this.$refs.addattribute.openSidebar()
      } else if (args.item.id === 'DeleteCustom') {
        this.alertAndConfirm([{ id: args.rowInfo.rowData.id }])
      }
  },
  editAttribute(title) {
    // console.log(title)
    this.$refs.addattribute.editTitle(title)
    // this.$refs.setattribute.editSet({ name, id })
  },
  // modal per aggiungere un set
  addAttribute(title) {
    this.$refs.addattribute.editTitle(title)
    // this.$refs.addAttribute.editSet({ name: '' })
  },
  pushAttributeToTable(attributes) {
    let typeOperation = ''
    attributes.forEach(element => {
        const index = this.$refs.overviewgrid_attributes.getRowIndexByPrimaryKey({ id: element.id})
        // console.log(index)
        if(index >= 0) {
          // console.log('dentro index >0')
          this.$refs.overviewgrid_attributes.updateRow(index, element)
          typeOperation = 'Aggiornato/i'
          // this.$refs.overviewgrid.deleteRecord()
        } else {
          typeOperation = 'Aggiunto/i'
          this.$refs.overviewgrid_attributes.addRecord(element, 0)
        }
      })
      this.$refs.toastRef.show({
        title: 'Operazione Completata!', content: `'Attributo/i ${typeOperation} Correttamente'`, cssClass: 'e-toast-success', icon: 'e-success toast-icons',
      })
      this.$refs.overviewgrid_attributes.refresh()
      return
  },
  dataBound: function() {
      this.$refs.overviewgrid_attributes.autoFitColumns();
    },
    toolbarClick(args) {
      // console.log(args)
      /* if (args.item.id === 'addProduct') {
        this.$refs['add-product-to-group'].openSidebar()
      } */
      if (args.item.id === 'overviewgrid_pdfexport') { // 'Grid_pdfexport' -> Grid component id + _ + toolbar item name
        const exportProperties = {
          // you can customize the name as per your requirement
          fileName: 'prova.pdf',
        }
        this.$refs.overviewgrid_attributes.pdfExport(exportProperties)
        // this.$refs.overviewgrid_attributes.pdfExport(exportProperties)
      }
      if (args.item.id === 'overviewgrid_excelexport') { // 'Grid_excelexport' -> Grid component id + _ + toolbar item name
        this.$refs.overviewgrid_attributes.excelExport()
      }
      if (args.item.id === 'overviewgrid_csvexport') { // 'Grid_excelexport' -> Grid component id + _ + toolbar item name
        this.$refs.overviewgrid_attributes.csvExport()
      }
    },
  },
  provide: {
      grid:[Toolbar, Filter, Selection, Sort, Edit, Page, ColumnChooser, RowDD, ContextMenu, CommandColumn, Resize, Scroll, Aggregate]
  }
}
</script>
<style>
 .detailtable td {
        font-size: 13px;
        padding: 4px;
        max-width: 0;
        overflow: hidden;
        text-overflow: ellipsis;
        white-space: nowrap;
    }

    .photo {
        width: 100px;
        height: 100px;
        border-radius: 50px;
        box-shadow: inset 0 0 1px #e0e0e0, inset 0 0 14px rgba(0,0,0,0.2);
    }

    @media screen and (max-width: 800px) and (min-width: 320px) {
        .photo {
            width: 70px;
            height: 70px;
        }
    }
</style>