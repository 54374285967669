/* eslint-disable  */
import axios from 'axios'

export function getSetAttributi() {
  // console.log('dentro logiiinnn')
  return new Promise((resolve, reject) => {
    axios.post(process.env.VUE_APP_URL_API_SERVER, {},
        {
            headers: {
                Authorization: `Bearer ${sessionStorage.getItem('token')}`,
                organizzazione: JSON.parse(sessionStorage.getItem('activeAgency')).value,
                classe: 'Diz-SetAttributo',
                metodo: 'getSetAttributi',
                ACL: '',
            },
        })
      .then(res => resolve(res))
      .catch(error => reject(error))
  })
}
export function setSetAttributi(setAttributo) {
    // console.log('dentro logiiinnn')
    return new Promise((resolve, reject) => {
      axios.post(process.env.VUE_APP_URL_API_SERVER, setAttributo,
          {
              headers: {
                  Authorization: `Bearer ${sessionStorage.getItem('token')}`,
                  organizzazione: JSON.parse(sessionStorage.getItem('activeAgency')).value,
                  classe: 'Diz-SetAttributo',
                  metodo: 'setSetAttributi',
                  ACL: '',
              },
          })
        .then(res => resolve(res))
        .catch(error => reject(error))
    })
  }
export function deleteSetAttributi(setAttributo) {
    // console.log('dentro logiiinnn')
    return new Promise((resolve, reject) => {
      axios.post(process.env.VUE_APP_URL_API_SERVER, setAttributo,
          {
              headers: {
                  Authorization: `Bearer ${sessionStorage.getItem('token')}`,
                  organizzazione: JSON.parse(sessionStorage.getItem('activeAgency')).value,
                  classe: 'Diz-SetAttributo',
                  metodo: 'deleteSetAttributi',
                  ACL: '',
              },
          })
        .then(res => resolve(res))
        .catch(error => reject(error))
    })
  }